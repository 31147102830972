<template>
<div class="image-report-view">
  <!-- <div class="header"> -->
  <!--   <div class="info"> -->
  <!--     {{ label }} -->
  <!--   </div> -->
  <!--   <div class="actions"> -->
  <!--   </div> -->
  <!-- </div> -->
  <div class="content">
    <div class="img-container">
      <img
        v-if="url"
        :src="image.url"/>
    </div>
    <div class="image-details">
      <InfoLine
        title="Name">
        {{ label }}
      </InfoLine>
      <InfoLine
        title="Date/time">
        {{ formatedDatetime }}
      </InfoLine>
      <InfoLine
        title="Latitude">
        {{ lat ? lat.toFixed(6) : '--' }}
      </InfoLine>
      <InfoLine
        title="Longitude">
        {{ lng ? lng.toFixed(6) : '--' }}
      </InfoLine>
      <InfoLine
        title="Altitude">
        {{ alt ? Math.round(alt) : 'Not available'}}
      </InfoLine>
      <InfoLine
        title="Direction">
        {{ direction ? Math.round(direction) + '&#176;': 'Not available'}}
      </InfoLine>
      <InfoLine
        title="Notes">
        {{ notes || 'Not available'}}
      </InfoLine>
    </div>

  </div>
  <!-- <div class="footer"> -->
  <!--   <div class="info row"> -->
  <!--   </div> -->
  <!--   <div class="actions row"> -->
  <!--   </div> -->
  <!-- </div> -->

</div>
</template>
<script>
import InfoLine from '@acorns-ui/Text/InfoLine'
import { get } from '@/utils';

export default {
  name: 'ImageReportView',
  props: {
    file: {
      type: File,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    notes: {
      type: String,
    },
    datetime: {
      type: String,
    },
    lat: {
      type: [Number, String],
    },
    lng: {
      type: [Number, String],
    },
    alt: {
      type: [Number, String],
    },
    direction: {
      type: [Number, String],
    },
  },
  data() {
    return {
      image: null,
      loading: false,
      width: null,
      height: null,
      options:  {
        maxWidth: 400,
        maxHeight: 500,
      }
    };
  },
  components: {
    InfoLine,
  },
  created() {
    if(this.file) {
      this.loadFileImage(this.file, this.options);
    }
  },
  watch: {
    name(a) {
      this.loadFileImage(a);
    }
  },
  computed: {
    url() {
      return get(['url'], this.image);
    },
    formatedDatetime() {
      if(this.datetime) {
        return this.datetime;//new Date(this.datetime).toLocaleString();
      } else {
        return '--';
      }
    },
  },
  methods: {
    loadFileImage(file, options = {}) {
      this.loading = true;
      this.$store.dispatch('loadFileImage', { file, options })
        .then( image => {
          this.image = image;
          this.width = image.width;
          this.height = image.height;
        }).finally( () => {
          this.loading = false;
        }).catch( err => {
          console.log('error loading file', err);
        })
    },
    pdf(doc) {
      //console.log('Document position', doc.autoTable);
      const sf = 1.3;//doc.internal.scaleFactor;
      var adj = 0.66;
     // doc.addImage(this.url, "JPEG", pos.x, pos.y, this.width/sf, this.height/sf);
      var width = this.width/sf * adj;
      var height = this.height/sf * adj;
      var notes = this.notes ? this.notes : '--';
      var label = this.label;

      var datetime = this.datetime
      var lat = this.lat ? this.lat.toFixed(6) : 'N/A';
      var lng = this.lng ? this.lng.toFixed(6) : 'N/A';
      var direction = this.direction;//.toFixed(0);
      var altitude = this.alt ? this.alt : '--';//.toFixed(1);

      //var notes = 'Notes: Eveniet quo corporis et id sit et dolor debitis facilis';
      var minHeight = height;
      var y = 0;

      //console.log(doc.pos, minHeight);
      // my checkfit method accounts for the scaling factor that
      // the addImage method does not but autotable does

      //doc.checkFit(this.width, (minHeight + 10) * sf);

      var fields = [
        'Name',
        'Date/Time',
        'Latitude',
        'Longitude',
        'Direction',
        'Altitude',
        'Notes',
      ];
      // console.log(doc.internal.pageSize);

      doc.addTableSection({
        minHeight,
        //startY: pos.y + 10,
        // head: [
        //   [
        //     {
        //       content: this.label,
        //       colSpan: 2,
        //     }
        //   ]
        // ],
        showHead: false,
        theme: 'grid',
        columnStyles: {
          0: { cellWidth: width },
          1: {},
        },
        body: [
          [{ rowSpan: 7 }, label ],
          [ datetime ],
          [ lat ],
          [ lng ],
          [ direction ],
          [ altitude],
          [ notes ],
         // ['footer', 'lajsdfkj'],
          // ...
        ],
       // didDrawPage: data => console.log(data),
        willDrawCell: ({ column, cell, row }) => {
          if(column.index == 1 && cell.section == 'body') {
            cell.text = '';
            if(row.index == (fields.length - 1) && (cell.height + y) < minHeight) {
              row.height = minHeight - y;
              cell.height = minHeight - y;
            }
          }
        },
        didDrawCell: ({ column, cell, row }) => {
          if(column.index == 0 && row.index == 0 && cell.section == 'body') {
            // console.log(table.height, table, cell);
            if(this.url) {
              doc.addImage({ img: this.url, format: "JPEG", x: cell.x, y: cell.y, width, height });
            }
          } else if(column.index == 1 && cell.section == 'body') {
            y += cell.height;
            doc.line({
              label: fields[row.index],
              text: cell.raw,
              x: cell.x + 5,
              y: cell.y,
              width: cell.width - 10,
            });
          }
        }
      })

      return(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.image-report-view  {
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    @include header;
    margin-bottom: 0;
    padding: 1rem;

    .info {
      font-size: 1.5rem;
    }
  }

  .content {
    flex: 1;
    display: flex;

    .img-container {
      display: flex;
      justify-content: center;
      flex: 1;
      border-right: $border-style;
      background-color: #3d424e;
      min-height: 18rem;
    }
    .image-details {
      flex: 1;
    }
  }

  .footer {
    @include footer;
    .info {
      flex: 1;
    }
  }
}
</style>

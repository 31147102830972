<template>
<div class="publish">

  <div class="page-container">
    <div class="menu-container">
      <div class="sticky-menu padded">

        <InfoLine
          class="row"
          :fill="true"
          title="Include images in download"
          @clicked="updateField('includeImagesInDownload', !includeImagesInDownload)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeImagesInDownload">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          :fill="true"
          title="Include PDF in download"
          @clicked="updateField('includePdfInDownload', !includePdfInDownload)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includePdfInDownload">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          :fill="true"
          title="Include manifest in download"
          @clicked="updateField('includeManifestInDownload', !includeManifestInDownload)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeManifestInDownload">
            check
          </IconLink>
        </InfoLine>


        <div class="row padded">
          <Button
            type="save"
            @click="downloadPackage">
            Start download
          </Button>
        </div>

      </div>
    </div>

    <div class="pdf-container">

      <div
        v-if="pdfsrc">
        <PdfViewer
          :multipage="true"
          :scale="1.25"
          :src="pdfsrc"/>
      </div>

    </div>

    <div class="ads-container">
      <div class="sticky-menu">
      </div>
    </div>

  </div>

</div>
</template>
<script>
import { mapState } from 'vuex';
import PdfViewer from '@acorns-ui/Pdf/PdfViewer';
import InfoLine from '@acorns-ui/Text/InfoLine';
import IconLink from '@acorns-ui/Icons/IconLink';
import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'Publish',
  props: {},
  data() {
    return {}
  },
  components: {
    PdfViewer,
    IconLink,
    Button,
    InfoLine,
  },
  created() {},
  watch: {},
  computed: {
    ...mapState([
      'pdfsrc',
      'includeImagesInDownload',
      'includePdfInDownload',
      'includeManifestInDownload',
    ]),
  },
  methods: {
    downloadPackage() {
      this.$store.dispatch('downloadPhotoPoints');
    },
    reset() {
      this.$store.dispatch('clearProperty');
      this.$router.push('/upload');
    },
    updateField(key, value) {
      this.$store.dispatch('updateField', { key, value });
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/modals';

/* .publish  {   */
/*   height: 100%; */
/*   width: 100%; */

/*   .page-container { */
/*     height: 100%; */
/*     width: 100%; */
/*     position: relative; */
/*     background-color: $white; */
/*     display: flex; */
/*     flex-direction: row; */

/*     .pdf-container { */
/*       margin: 0 4rem; */
/*     } */

/*   } */

/* } */
.publish {
  flex: 1;
  overflow-y: auto;

  .sticky-menu {
    position: sticky;
    top: 1rem;
  }

  .page-container {

    position: relative;
    background-color: $white;
    display: flex;

    .menu-container {
      padding: 1rem;
    }

    .pdf-container {
      margin: 1rem;
      width: 75%;
    }

    .ads-container {
      flex: 1;
      padding: 1rem;
      background-color: $white;
    }

  }

}
</style>

<template>
  <div class="photo-point-location-report">

    <header class="header">
      <InfoLine
        title="Label">
        {{ label }}
      </InfoLine>
      <InfoLine
        title="Location">
        {{ lat }} / {{ lng }}
      </InfoLine>
    </header>

    <div
      class="photo-points-container">
      <div
        class="photo-point-image-container"
        v-for="p in points"
        :key="p.uid">
        <ImageReportView
          :ref="`pt${p.uid}`"
          :label="`${label}${p.label}`"
          v-bind="p.image"/>
      </div>
    </div>

</div>
</template>
<script>
//import ImageDrop from '@/components/ImageDrop';
import ImageReportView from '@/components/ImageReportView';
import InfoLine from '@acorns-ui/Text/InfoLine'
//import { get } from '@/utils';

export default {
  name: 'PhotoPointLocation',
  props: {
    label: {
      type: String,
      default: 'PPT: 1'
    },
    lat: {
      type: Number,
    //  default: 45.1254,
    },
    lng: {
      type: Number,
     // default: -123.5698,
    },
    points: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return { };
  },
  components: {
    ImageReportView,
    InfoLine,
  },
  created() {},
  watch: {},
  computed: {
  },
  methods: {
    async pdf(doc) {
      // add the header
      //console.log('the current position', doc.cy);
      doc.addHeader({ text: this.label })
      if(this.lat) {
        let text = `Location: ${this.lat}, ${this.lng}`;
        doc.addLine({ text, size: 14, padding: 2})
      }
      // then add the images
      await this.points.map(p => {
        const key = `pt${p.uid}`;
        const comp = this.$refs[key];
        //console.log('printing pt', key, doc.pos);
        if(comp && comp.pdf) {
          comp.pdf(doc);
          // check to see if we need to add a page
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/modals';

.photo-point-location-report  {
  width: 100%;

  .header {
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: white;
  }

  .photo-point-image-container {
    display: flex;
    flex-direction: row;
    border: $border-style;
    margin-bottom: 1rem;
    .image-container {
      flex: 0 0 30rem;
      min-height: 30rem;
    }
    .details-container {
      flex: 1;
    }
  }


  .modal-container {
    @include modal;
  }
}
</style>

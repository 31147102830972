<template>
<div class="report">


  <div class="report-container">
    <div class="options-container">
      <div class="sticky-menu">

        <InfoLine
          class="row"
          :fill="true"
          title="Include Report Header"
          @clicked="updateField('includeReportHeader', !includeReportHeader)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeReportHeader"
            @clicked="updateField('includeReportHeader', !includeReportHeader)">
            check
          </IconLink>
        </InfoLine>


        <InfoLine
          class="row"
          title="Include dates"
          @clicked="updateField('includeDates',!includeDates)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeDates"
            @clicked="updateField('includeDates',!includeDates)">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          title="Include report notes"
          @clicked="updateField('includeReportNotes', !includeReportNotes)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeReportNotes"
            @clicked="updateField('includeReportNotes', !includeReportNotes)">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          title="Include Empty Photo Points"
          @clicked="updateField('includeEmptyPhotoPoints', !includeEmptyPhotoPoints)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeEmptyPhotoPoints"
            @clicked="updateField('includeEmptyPhotoPoints', !includeEmptyPhotoPoints)">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          v-if="tray.length"
          class="row"
          title="Include tray images"
          @clicked="updateField('includeTrayImages', !includeTrayImages)">
          <div class="fill"/>
          <IconLink
            :success="includeTrayImages"
            @clicked="updateField('includeTrayImages', !includeTrayImages)">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          title="Include map"
          @clicked="updateField('includeMap', !includeMap)">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="includeMap"
            @clicked="updateField('includeMap', !includeMap)">
            check
          </IconLink>
        </InfoLine>

        <InfoLine
          class="row"
          title="Show map in landscape orientation"
          @clicked="updateField('landscapeMap', !landscapeMap); mapVersion++">
          <div class="fill"/>
          <IconLink
            :small="true"
            :success="landscapeMap"
            @clicked="updateField('landscapeMap', !landscapeMap); mapVersion++">
            check
          </IconLink>
        </InfoLine>

        <div class="">
          <div class="padded italics smaller">
            Sort photo points by
          </div>

          <InfoLine
            class="row report-option"
            title="Location label"
            @clicked="sortBy('label')">
            <div class="fill"/>
            <IconLink
              v-if="sortPhotoPointLocationsBy == 'label'"
              :small="true"
              :success="true">
              check
            </IconLink>
          </InfoLine>

          <InfoLine
            class="row report-option"
            title="Picture time"
            @clicked="sortBy('time')">
            <div class="fill"/>
            <IconLink
              v-if="sortPhotoPointLocationsBy == 'time'"
              :small="true"
              :success="true">
              check
            </IconLink>
          </InfoLine>

          <InfoLine
            class="row report-option"
            title="Location latitude"
            @clicked="sortBy('lat')">
            <div class="fill"/>
            <IconLink
              v-if="sortPhotoPointLocationsBy == 'lat'"
              :small="true"
              :success="true">
              check
            </IconLink>
          </InfoLine>

          <InfoLine
            class="row report-option"
            title="Location longitude"
            @clicked="sortBy('lng')">
            <div class="fill"/>
            <IconLink
              v-if="sortPhotoPointLocationsBy == 'lng'"
              :small="true"
              :success="true">
              check
            </IconLink>
          </InfoLine>

        </div>


        <div class="row padded">
          <Button
            type="save"
            @click="pdf">
            {{ publishable ? 'reprint report' : 'print report' }}
          </Button>
        </div>


      </div>
    </div>


    <div class="pages-container">

      <div class="page">

        <h1>
          <a @click="editForm('reportTitle', reportTitle)">
            {{ reportTitle }}
          </a>
        </h1>

        <section
          v-if="includeReportHeader"
          id="report-info"
          class="section">


          <InfoLine
            class="row"
            title="Organization"
            @click="editForm('organizationName', organizationName)">
            {{ organizationName }}
          </InfoLine>

          <InfoLine
            class="row"
            title="Property"
            @click="editForm('propertyName', propertyName)">
            {{ propertyName }}
          </InfoLine>

          <InfoLine
            class="row"
            title="Monitor"
            @click="editForm('monitorsName', monitorsName)">
            {{ monitorsName }}
          </InfoLine>

          <InfoLine
            v-if="includeDates"
            class="row"
            title="Photo dates">
            {{ firstImageDate }} to {{ lastImageDate }}
          </InfoLine>

        </section>

        <InfoLine
          v-if="includeReportNotes"
          title="General notes"
          @click="editForm('reportNotes', reportNotes, 'memo')">
          <div
            class="report-notes clickable"
            v-html="reportNotes.replace(/\n/g, '<br/>')"/>
        </InfoLine>


      <!-- </div> -->
      <!-- <div class="page"> -->

        <section
          id="report-photo-points"
          class="section">
          <PhotoPointLocationReport
            :id="`pp${pl.uid}`"
            v-for="pl in filteredData"
            :key="pl.uid"
            :label="`${prefix}${pl.label}`"
            :lat="pl.lat"
            :lng="pl.lng"
            :points="pl.photopoints"
            :ref="`pp${pl.uid}`"/>
        </section>
      </div>

      <div
        v-if="showTrayImages"
        class="page">
        <section
          id="report-photos"
          class="section">
          <h1>
            Additional Photos
          </h1>

          <ImageReportView
            class="additional-image"
            v-for="p in tray"
            :key="p.name"
            :ref="`tp${p.name}`"
            :datetime="p.datetime"
            :label="p.name"
            :lat="p.lat"
            :lng="p.lng"
            :alt="p.alt"
            :azimuth="p.direction"
            :notes="p.notes"
            :file="p.file"/>
        </section>
      </div>

      <div
        v-if="includeMap"
        class="page"
        :class="{ landscape: landscapeMap }">
        <section
          id="report-map"
          class="section">
          <LeafletMap
            ref="map"
            :layers="[imagesGeojson, cameraPointsGeojson]"
            :center="center"
            />
          <img :src="url">
        </section>
      </div>

    </div>

    <div class="options-container">
      <div class="toc sticky-menu">
        <h2>
          Table of contents
        </h2>
        <ul>
          <li class="toc-item">
            <a
              @click="scrollTo(`report-info`)">
              Site information
            </a>
          </li>
          <li class="toc-item">
            <a
              @click="scrollTo(`report-photo-points`)">
              Photo Points
            </a>
            <ul>
              <li
                class="toc-item"
                v-for="pl in filteredData"
                :key="pl.uid">
                <a
                  @click="scrollTo(`pp${pl.uid}`)">
                  {{ `${prefix}${pl.label}` }}
                </a>
              </li>
            </ul>
          </li>
          <li
            v-if="showTrayImages"
            class="toc-item">
            <a
              @click="scrollTo(`report-photos`)">
              Additional Photos
            </a>
          </li>
          <li
            v-if="includeMap"
            class="toc-item">
            <a
              @click="scrollTo(`report-map`)">
              Site Map
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div
    v-if="pdfsrc"
    @click="pdfsrc = false"
    class="modal-container">
    <PdfViewer
      :src="pdfsrc"/>
  </div>

  <div
    v-if="editableField"
    class="modal-container">
    <EditForm
      :value="editableField.value"
      :type="editableField.type"
      @update="updateField(editableField.key, $event)"
      @cancel="editableField = null"/>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import Table from '@/components/Table';
import PhotoPointLocationReport from '@/components/PhotoPointLocationReport';
import LeafletMap from '@/components/LeafletMap';
import InfoLine from '@acorns-ui/Text/InfoLine';
import IconLink from '@acorns-ui/Icons/IconLink';
//import EditableMarkdownDiv from '@/components/EditableMarkdownDiv';
import ImageReportView from '@/components/ImageReportView';
import EditForm from '@/components/EditForm';
import PDF from '@acorns-ui/Pdf/pdf'
import PdfViewer from '@acorns-ui/Pdf/PdfViewer';
import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'Report',
  props: {},
  data() {
    return {
      editableField: null,
      url: null,
      pdfsrc: null,
      fontSize: 14,
    };
  },
  components: {
    // Table,
    //EditableMarkdownDiv,
    PhotoPointLocationReport,
    LeafletMap,
    InfoLine,
    IconLink,
    PdfViewer,
    ImageReportView,
    EditForm,
    Button,
  },
  created() {},
  watch: {},
  computed: {
    ...mapState([
      'additionalPhotos',
      'tray',
    ]),
    ...mapState('photopoints', [
      'data',
    ]),
    ...mapGetters('photopoints', [
      'center',
    ]),
    ...mapGetters([
      'publishable',
      'cameraPointsGeojson',
      'imagesGeojson',
    ]),
    ...mapState([
      'prefix',
      'reportTitle',
      'reportNotes',
      'organizationName',
      'monitorsName',
      'propertyName',
      'sortPhotoPointLocationsBy',
      'includeMap',
      'includeDates',
      'includeReportHeader',
      'includeReportNotes',
      'includeEmptyPhotoPoints',
      'landscapeMap',
      'includeTrayImages',
    ]),
    fields() {
      return [
        { key: 'label', caption: 'Photo Point'},
        { key: 'lat', caption: 'Lat'},
        { key: 'lng', caption: 'Lng'},
      ]
    },
    filteredData() {
      if(this.includeEmptyPhotoPoints) {
        return this.data;
      } else {
        return this.data.map( d => {
          const photopoints = d.photopoints.filter(p => p.image && p.image.file);
          return { ...d, photopoints };
        }).filter(d => d.photopoints.length)
      }
    },
    firstImageDate() {
      var dates = this.data
          .map(d => d.photopoints
               .filter(d => d.image)
               .map(d => d.image.datetime))
          .flat()
      return [...dates].sort((a,b) => a > b ? 1 : -1)[0]
    },
    lastImageDate() {
      var dates = this.data
          .map(d => d.photopoints
               .filter(d => d.image)
               .map(d => d.image.datetime))
          .flat()
      return [...dates].sort((a,b) => a < b ? 1 : -1)[0]
    },
    reportDatesString() {
      var f = this.firstImageDate;
      var l = this.lastImageDate;
      return `${f} - ${l}`
    },
    reportLogo() {
      return 'LOGO';
    },
    showTrayImages() {
      return this.includeTrayImages && this.tray.length;
    },
  },
  methods: {
    sortBy(key) {
      this.$store.commit('photopoints/SORT_RECORDS', { key });
      this.updateField('sortPhotoPointLocationsBy', key);
    },
    editForm(key, value, type = 'text') {
      this.editableField = { key, value, type}
    },
    updateField(key, value) {
      this.$store.dispatch('updateField', { key, value });
      this.editableField = null;
    },
    scrollTo(hash){
      const el = document.getElementById(hash);
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    },
    downloadPhotoPoints() {
      this.$store.dispatch('downloadPhotoPoints');
    },
    printReportHeader(doc) {

      doc.addInfoLine({
        label: 'Organization',
        text: this.organizationName,
        size: this.fontSize,
        //padding: 5
      });

      doc.addInfoLine({
        label: 'Property',
        text: this.propertyName,
        size: this.fontSize,
      });

      doc.addInfoLine({
        label: 'Monitor(s)',
        text: this.monitorsName,
        size: this.fontSize,
      });

      if(this.includeDates) {
        doc.addInfoLine({
          label: 'Photo date(s)',
          text: this.reportDatesString,
          size: this.fontSize,
        });
      }

    },
    async pdf() {
      // http://raw.githack.com/MrRio/jsPDF/master/

      const doc = new PDF();
      console.log(doc)

      doc.addTitle({
        text: this.reportTitle,
      });

      // Report header
      if(this.includeReportHeader) {
        this.printReportHeader(doc);
      }

      if(this.includeReportNotes) {
        doc.addLabeledLine({
          label: 'Generic notes',
          text: this.reportNotes,
          size: this.fontSize,
        });
      }

      // print the photo point locations
      this.filteredData.map( d => {
        const key = `pp${d.uid}`;
        const comp = this.$refs[key];
        if(comp && comp.pdf) {
          comp.pdf(doc);
        }
      })

      if(this.showTrayImages) {
        doc.addTitle({
          text: 'Additional Photos',
        });
        this.tray.map( d => {
          const key = `tp${d.name}`;
          const comp = this.$refs[key];
          if(comp && comp.pdf) {
            comp.pdf(doc);
          }
        })
      }

      // print the map
      if(this.includeMap) {
        let orientation = this.landscapeMap ? 'l' : 'p';
        await this.$refs.map.pdf(doc, { orientation })
      }

      // //doc.save("report.pdf");
      doc.addPageNumbers();
      doc.addFooterText('Printed on ' + new Date().toLocaleString());
      doc.addHeaderText(this.reportTitle);

      this.$store.dispatch('updateField', {
        key: 'pdfsrc',
        value: doc.output('arraybuffer'),
      });

      this.$router.push('/publish');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/modals';

.report  {
  flex: 1;
  overflow-y: auto;

  h1 {
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .modal-container {
    @include modal;
  }

  .report-notes {
    padding: 0.5rem 0;
  }

  .options-container {
    //height: 10rem;
    padding: 1rem;
    background-color: $white;
  }

  #report-map {
    height: 800px;
    width: 565px;
  }

  .sticky-menu {
    // background-color: pink;
    position: sticky;
    top: 1rem;
  }

  .toc {

    ul {
      list-style-type: none;
      padding-left: 0.5rem;
    }

    >ul {
      padding-left: 0;
    }

    .toc-item {
      font-size: 0.875rem;
    }
    a {
      display: block;
      cursor: pointer;
      padding: 0.25rem;
      &:hover {
        background-color: $hover-bkgd;
      }
    }
  }

  .selected {
    color: steelblue !important;
  }

  .report-container {
    position: relative;
    background-color: $white;
    display: flex;

    .options-container {
      flex: 1;
      padding: 1rem;

    }

    .pages-container {
      width: 11 * $inch;

      .page {
        @include shadow;
        //border: $border-style;
        background-color: white;
        min-height: (11 - 0.75*2) * $inch;
        width: (8.5 - 0.75*2) * $inch;

        margin: 0 auto;
        padding: 0.75*$inch 0.75*$inch;
        margin-top: 1rem;

        .page-header {
          height: 1*$inch;
          margin: 0.5rem;
        }
        .page-footer {
          height: 1*$inch;
        }

        .additional-image {
          margin-bottom: 1rem;
        }

        &.landscape {
          height: (8.5 - 1*2) * $inch;
          width: (11 - 0.75*2) * $inch;
          min-height: (8.5 - 0.75*2) * $inch;
          #report-map {
            width: (11 - 0.75*2) * $inch;
            height: (8.5 - 1*2) * $inch;
          }
        }
      }
    }
  }

  .report-title {
    //z-index: 10;
    //position: sticky;
    //top: 0;
  }


  .section {
    //border: $border-style;
    //padding: 1rem;
    //margin: 0.5rem;
  }
}
</style>
